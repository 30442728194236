import { User, UserResponse } from "../models";

/**
 * Check if the all organizations have integrated with SSO
 * @param user
 * @returns
 */
export function onlySSOIntegration(user: UserResponse | undefined) {
  if ((user?.userOrgs ?? []).length === 0) {
    return false;
  }
  return user?.userOrgs.every((org) => org.metadata.ssoEnabled);
}

/**
 * Check if the user's organizations has SSO integration
 * @param user
 * @returns
 */
export function hasSSOIntegration(user: UserResponse | undefined) {
  return user?.userOrgs.some((org) => org.metadata.ssoEnabled);
}

const ServiceDomainMap: Record<string, string> = {
  TrackTraining: ".train",
  TrackTalentHub: ".hub",
};

function getServiceURL(
  locationHost: string,
  serviceName: string,
  orgName: string
) {
  let subdomain = "";
  let trailingPath = "/";
  if (orgName === "track-training-sa") {
    subdomain = "givery.train";
    trailingPath = "/auth/sa/login";
  } else if (orgName === "track-test-sa") {
    subdomain = "admin";
  } else {
    const serviceDomain = ServiceDomainMap[serviceName] ?? "";
    subdomain = `${orgName}${serviceDomain}`;
  }

  let baseUrl;
  switch (locationHost) {
    case "localhost":
    case "accounts.tracks-dev.tokyo":
      baseUrl = `https://${subdomain}.tracks-dev.tokyo`;
      break;
    case "accounts.tracks-stage.tokyo":
      baseUrl = `https://${subdomain}.tracks-stage.tokyo`;
      break;
    case "accounts.tracks.run":
    default:
      baseUrl = `https://${subdomain}.tracks.run`;
  }

  return new URL(trailingPath, baseUrl).toString();
}

function buildOrgIdToServiceNamesMap(user: User) {
  return user.userMetadata.services.reduce((acc, service) => {
    acc[service.orgId] = [...(acc[service.orgId] ?? []), service.name];
    return acc;
  }, [] as Record<number, string[]>);
}

function getServiceDisplayName(serviceName: string) {
  switch (serviceName) {
    case "track-training-sa":
      return "Track Training";
    case "track-test-sa":
      return "Track Test";
    case "TrackTraining":
      return "Track Training";
    case "TrackTalentHub":
      return "Track Hub";
    case "TrackTest":
      return "Track Test";
    default:
      return "Unknown Service";
  }
}

/**
 * Extend orgs with links to the services which the user is registered to
 *
 * @param user UserResponse
 * @param locationHost string
 * @returns user.userOrgs with serviceLinks
 */
export function extendOrgsWithServiceLinks(
  user: UserResponse,
  locationHost: string
) {
  const orgIdToOrgTypeMap = buildOrgIdToServiceNamesMap(user.user);

  return user.userOrgs.map((org) => ({
    ...org,
    serviceLinks: (
      orgIdToOrgTypeMap[org.metadata.organizationId] || [org.name]
    ).map((serviceName) => ({
      displayName: getServiceDisplayName(serviceName),
      url: getServiceURL(locationHost, serviceName, org.name),
    })),
  }));
}
